import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { colors } from "../styles/theme"

const Preview = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: fit-content;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 2fr 1fr;
  border: 1px solid ${colors.textLight};

  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */
  -khtml-border-radius: 10px; /* for old Konqueror browsers */

  -webkit-box-shadow: 0 8px 6px -6px ${colors.mediumGrey};
  -moz-box-shadow: 0 8px 6px -6px ${colors.mediumGrey};
  box-shadow: 0 8px 6px -6px ${colors.mediumGrey};

  a {
    background: linear-gradient(to bottom, ${colors.gold} 0%, ${colors.gold} 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 4px 4px;
    color: #000;
    text-decoration: none;
    transition: background-size 0.4s;

    &:hover {
      color: white;
      background-size: 4px 50px;
      padding: 0.2rem 0.6rem;
    }
  }

  &:hover {
    -webkit-box-shadow: 0 12px 9px -9px ${colors.mediumGrey};
    -moz-box-shadow: 0 12px 9px -9px ${colors.mediumGrey};
    box-shadow: 0 12px 9px -9px ${colors.mediumGrey};
  }

  @media (min-width: 320px) and (max-width: 767px) {
    width: 90%;
    margin: 1rem auto;
    // height: 9.5rem;
    grid-template-columns: 3fr 1fr;
  }
`

const Title = styled.span`
  justify-self: start;
  align-self: end;

  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  color: ${colors.textDark};

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1.2rem;
    padding-left: 0.8rem;
    // align-self: center;
  }
`

const SubTitle = styled.span`
  justify-self: start;
  align-self: start;

  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  padding-left: 1rem;
  padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  color: ${colors.textLight};

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-top: 0rem;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    padding-top: 0.2rem;
  }
`

const Date = styled.span`
  justify-self: end;
  align-self: end;

  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  color: ${colors.textDark};
  padding-right: 1rem;
  padding-bottom: 0.5rem;

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    padding-top: 0.5rem;
    text-align: right;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    font-size: 1rem;
    letter-spacing: 1.25px;
    padding-right: 1rem;
    padding-top: 1rem;
  }
`

const Category = styled.span`
  justify-self: end;
  align-self: start;

  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: white;
  background-color: ${colors.backgroundGrey};
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem 0.6rem;
  letter-spacing: 1px;

  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; /* future proofing */
  -khtml-border-radius: 3px; /* for old Konqueror browsers */

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    padding: 0.1rem 0.3rem;
    letter-spacing: 0.5px;
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    justify-self: end;
    align-self: start;

    font-size: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.6rem;
    letter-spacing: 0.75px;
  }
`

const BlogPreview = ({ post }) => (
  <Preview>
    <Title>
      <Link to={`/${post.slug}`}>{post.title}</Link>
    </Title>
    <Date>{post.date}</Date>
    <SubTitle>{post.subtitle}</SubTitle>
    <Category>{post.category}</Category>
  </Preview>
)

export default BlogPreview

import React from "react"
import SEO from "../component/SEO/seo"
import UsePosts from "../hooks/use-posts"
import Layout from "../component/layout"
import BlogPreview from "../component/blog-preview"

export default () => {
  const posts = UsePosts()
  const seo = {
    frontmatter: {
      title: "All Posts",
      slug: "blog",
    },
  }

  return (
    <Layout>
      <SEO {...seo} />
      {posts.map(post => (
        <BlogPreview key={post.slug} post={post} />
      ))}
    </Layout>
  )
}

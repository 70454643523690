import { graphql, useStaticQuery } from 'gatsby';

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
        allMdx(sort:{fields: [frontmatter___date], order:DESC}) {
            nodes {
                excerpt(pruneLength: 80)
                frontmatter {
                    title
                    slug
                    author
                    subtitle
                    date(formatString: "DD MMMM, YYYY")
                    category
                    tags
                    image {
                        id
                    }
                }
            }
            totalCount
        }
    }
  `);

  return data.allMdx.nodes.map(post => ({
    excerpt: post.excerpt,
    title: post.frontmatter.title,
    slug: post.frontmatter.slug,
    author: post.frontmatter.author,
    subtitle: post.frontmatter.subtitle,
    date: post.frontmatter.date,
    category: post.frontmatter.category,
    tags: post.frontmatter.tags,
    image: post.frontmatter.image,
  }));
};

export default usePosts;

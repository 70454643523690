import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";
import SchemaOrg from "./schema-org";

const SEO = ({ postData, frontmatter = {}, postImage, isBlogPost }) => (
    <StaticQuery
        query={query}
        render={( queryResult ) => {
            const seo = queryResult.site.siteMetadata;
            const postMeta = frontmatter || postData.childMarkdownRemark.frontmatter || null;
            const title = postMeta.title || seo.title;
            const description = postMeta.description || seo.description;
            const image = postImage ? `${seo.siteUrl}${postImage}` : seo.image;
            const url = postMeta.slug
                ? `${seo.siteUrl}/${postMeta.slug}/`
                : seo.siteUrl;
            const datePublished = isBlogPost ? postMeta.datePublished : false;

            return (
                <>
                    <Helmet>
                        {/* General Tags */}
                        <title>{title}</title>
                        <meta name="description" content={description} />
                        <meta name="image" content={image} />
                        <link rel="canonical" href={url} />

                        {/* Opengraph Tags */}
                        <meta property="og:url" content={url} />
                        {isBlogPost ? <meta property="og:type" content="article" /> : null}
                        <meta property="og:title" content={title} />
                        <meta property="og:site_name" content={seo.organization.name} />
                        <meta property="og:description" content={description} />
                        <meta property="og:image" content={image} />
                        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

                        {/* Twitter Tags */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:creator" content={seo.social.twitter} />
                        <meta name="twitter:site" content={seo.social.twitter} />
                        <meta name="twitter:title" content={title} />
                        <meta name="twitter:description" content={description} />
                        {/* Twitter Summary card images must be at least 120x120px */}
                        <meta name="twitter:image" content={image} />
                    </Helmet>
                    <SchemaOrg 
                        isBlogPost={isBlogPost}
                        url={url}
                        title={title}
                        image={image}
                        description={description}
                        datePublished={datePublished}
                        siteUrl={seo.siteUrl}
                        author={seo.author}
                        organization={seo.organization}
                        defaultTitle={seo.title}
                    />
                </>
            );
        }}
    />
);

SEO.propTypes = {
    postData: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
            frontmatter: PropTypes.any,
            excerpt: PropTypes.any,
        }),
    }),
    postImage: PropTypes.string,
    isBlogPost: PropTypes.bool,
};

SEO.defaultProps = {
    isBlogPost: false,
    postData: { childMarkdownRemark: {} },
    postImage: null,
};

export default SEO;

const query = graphql`
    {
        site {
            siteMetadata {
                title
                titleTemplate
                description
                siteUrl
                image
                author {
                    name
                }
                organization {
                    name
                    url
                    logo
                }
                social {
                    twitter
                    fbAppID
                    github
                    linkedin
                    stackoverflow
                }
            }
        }
    }
`;
